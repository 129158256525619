import "../style.scss";
import { StepProps, Steps } from "antd";
import boxActiveIcon from "../../../assets/images/icons/box-active.png";
import boxSuccessIcon from "../../../assets/images/icons/box-success.png";
import truckInactiveIcon from "../../../assets/images/icons/truck.png";
import truckActiveIcon from "../../../assets/images/icons/truck-active.png";
import truckSuccessIcon from "../../../assets/images/icons/truck-success.png";
import checkCircleIcon from "../../../assets/images/icons/check-circle.png";
import checkCircleActiveIcon from "../../../assets/images/icons/check-circle-active.png";
import { RightOutlined } from "@ant-design/icons";
import { StoreOwner } from "../Slice";
import { GetDate, GetTime } from "../../../utils/GetDateNow";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNavigate, useSearchParams } from "react-router-dom";
dayjs.extend(relativeTime);

enum ShippingStatus {
  waiting = "waiting",
  inTransit = "in-transit",
  success = "success",
}

enum ShippingType {
  pickup = "pickup",
  delivery = "delivery",
  success = "success",
}

enum IconStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
  SUCCESS = "success",
}

function SummaryComponent({ data }: { data: StoreOwner }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const checkShippingStatus = (status: string, isPickedup: boolean) => {
    switch (status) {
      case "waiting":
      case "in-transit":
        if (isPickedup === false) {
          return ShippingStatus.waiting;
        } else {
          return ShippingStatus.inTransit;
        }
      case "success":
        return ShippingStatus.success;
      default:
        return ShippingStatus.waiting;
    }
  };

  const checkCurrentStep = (status: string, isPickedup: boolean) => {
    switch (status) {
      case "waiting":
      case "in-transit":
        if (isPickedup === false) {
          return 0;
        } else {
          return 1;
        }
      case "success":
        return 2;
      default:
        return 0;
    }
  };

  return data.shipping_infos?.map((info, index) => {
    const onDetailClick = () => {
      const path = window.location.pathname;
      navigate(`${path}/${info.truck_plate_number}?${searchParams.toString()}`);
    };

    const displayDate = (status: string): string => {
      const etaTimeStart = GetDate(info.eta_start);
      const etaTimeEnd = GetTime(info.eta_end);

      if (status === "success") {
        return info.status_updated_at !== null ? GetDate(info.status_updated_at) : "N/A";
      } else {
        return info.eta !== null ? etaTimeStart + " - " + etaTimeEnd : "N/A";
      }
    };

    return (
      <div
        className="summary-box"
        style={{ cursor: "pointer" }}
        onClick={onDetailClick}
        key={info.truck_plate_number + index}
        data-testid="store-owner-summary-box"
      >
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <InfoComponent
              testId="shipping-truck-plate-number"
              title="ทะเบียนรถ"
              content={info.truck_plate_number}
            />
            <InfoComponent title="ประเภทรถ" content={info.truck_type} />
          </div>
          <div className="section-eta-time">
            <InfoComponent
              testId="shipping-eta-time"
              title="เวลาถึงโดยประมาณ"
              content={displayDate(info.status)}
            />
            {info.delay_delivery && <span className="delay-eta-text">(ปรับเวลาจัดส่ง)</span>}
          </div>
          <Steps
            className="step-icon"
            current={checkCurrentStep(info.status, info.is_picked_up)}
            labelPlacement="vertical"
            items={ShippingStatusItems({
              status: checkShippingStatus(info.status, info.is_picked_up),
              etaTime: info.eta,
            })}
            responsive={false}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginLeft: "16px" }}>
          <RightOutlined />
        </div>
      </div>
    );
  });
}

export default SummaryComponent;

export function InfoComponent({
  title,
  content,
  testId,
}: {
  title: string;
  content: string;
  testId?: string;
}) {
  return (
    <div className="info-text" data-testid={testId}>
      <span className="text-title">{`${title} :`}</span>
      <span className="text-content">{` ${content}`}</span>
    </div>
  );
}

export function ShippingStatusItems({
  status,
  etaTime,
}: {
  status: ShippingStatus;
  etaTime: string;
}): StepProps[] | undefined {
  const shippingItems: Record<
    string,
    {
      iconStatus: IconStatus;
      description: string;
    }
  > = {
    pickup: {
      iconStatus: IconStatus.INACTIVE,
      description: "รอดำเนินการ",
    },
    delivery: {
      iconStatus: IconStatus.INACTIVE,
      description: "รอดำเนินการ",
    },
    success: {
      iconStatus: IconStatus.INACTIVE,
      description: "รอดำเนินการ",
    },
  };

  switch (status) {
    case ShippingStatus.waiting:
      shippingItems.pickup = {
        iconStatus: IconStatus.ACTIVE,
        description: "กำลังดำเนินการ",
      };
      shippingItems.delivery = {
        iconStatus: IconStatus.INACTIVE,
        description: "รอดำเนินการ",
      };
      shippingItems.success = {
        iconStatus: IconStatus.INACTIVE,
        description: "รอดำเนินการ",
      };
      break;
    case ShippingStatus.inTransit:
      shippingItems.pickup = {
        iconStatus: IconStatus.SUCCESS,
        description: "เสร็จสิ้น",
      };
      shippingItems.delivery = {
        iconStatus: IconStatus.ACTIVE,
        description: "กำลังดำเนินการ",
      };
      shippingItems.success = {
        iconStatus: IconStatus.INACTIVE,
        description: "รอดำเนินการ",
      };
      break;
    case ShippingStatus.success:
      shippingItems.pickup = {
        iconStatus: IconStatus.SUCCESS,
        description: "เสร็จสิ้น",
      };
      shippingItems.delivery = {
        iconStatus: IconStatus.SUCCESS,
        description: "เสร็จสิ้น",
      };
      shippingItems.success = {
        iconStatus: IconStatus.SUCCESS,
        description: "เสร็จสิ้น",
      };
      break;
  }

  const displayDeliveryTitle = (): string => {
    if (status === ShippingStatus.inTransit) {
      return "อยู่ระหว่างการจัดส่ง";
    } else {
      return "การจัดส่งสินค้า";
    }
  };

  return [
    {
      title: "รับสินค้าที่คลัง",
      description: shippingItems.pickup.description,
      icon: <ShippingStatusIcon status={shippingItems.pickup.iconStatus} type={ShippingType.pickup} />,
    },
    {
      title: displayDeliveryTitle(),
      description: shippingItems.delivery.description,
      icon: <ShippingStatusIcon status={shippingItems.delivery.iconStatus} type={ShippingType.delivery} />,
    },
    {
      title: "จัดส่งสำเร็จ",
      description: shippingItems.success.description,
      icon: <ShippingStatusIcon status={shippingItems.success.iconStatus} type={ShippingType.success} />,
    },
  ];
}

function ShippingStatusIcon({ status, type }: { status: IconStatus; type: ShippingType }) {
  const icons: Record<string, Record<IconStatus, string>> = {
    pickup: {
      inactive: boxActiveIcon,
      active: boxActiveIcon,
      success: boxSuccessIcon,
    },
    delivery: {
      inactive: truckInactiveIcon,
      active: truckActiveIcon,
      success: truckSuccessIcon,
    },
    success: {
      inactive: checkCircleIcon,
      active: checkCircleIcon,
      success: checkCircleActiveIcon,
    },
  };

  const icon = icons[type][status];

  return (
    <div>
      <img
        style={{ alignItems: "center", justifyItems: "center" }}
        src={icon}
        alt={icon}
        width={"32px"}
      ></img>
    </div>
  );
}
